
import { i18n } from '@/plugins/element'
import dayjs from 'dayjs'
const t = i18n.global.t
// 子用户申请详情字段
export const detailInfo = [
  { // 申请创建时间
    label: t('assign.application_creation_time'),
    prop: 'created_at'
  },
  { // 申请编号
    label: t('assign.application_id'),
    prop: 'id'
  },
  { // 申请来源
    label: t('assign.application_source'),
    prop: 'source'
  },
  { // 项目
    label: t('dashboard.project'),
    prop: 'project_name'
  },
  { // 所属子用户
    label: t('project.sub_user'),
    prop: 'create_user'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name'
  },
  { // 申请数量
    label: t('assign.application_count'),
    prop: 'content_value'
  },
  { // 审核状态
    label: t('enterprise.review_status'),
    prop: 'status'
  },
  { // 产品用户名
    label: t('product.user_product'),
    prop: 'saas_user',
    span: 2
  },
  { // 注册码
    label: t('project.license'),
    prop: 'lic_key',
    span: 2
  },
  { // 激活码
    label: t('assign.license_secret'),
    prop: 'lic_secret',
    span: 2
  },
  { // 发送邮箱
    label: t('assign.send_email'),
    prop: 'send_email',
    type: 'slot',
    span: 2
  },
  { // 授权下发状态
    label: t('assign.sub_issuance_status'),
    prop: 'send_issuse',
    type: 'slot',
    span: 2
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment',
    span: 2
  }
]

// 扫码申请详情字段
export const scanDescriptions = [
  { // 创建时间
    label: t('table.creation_time'),
    prop: 'created_at'
  },
  { // 申请编号
    label: t('assign.application_id'),
    prop: 'id'
  },
  { // 申请来源
    label: t('assign.application_source'),
    prop: 'source'
  },
  { // 产品用户名
    label: t('product.user_product'),
    prop: 'saas_user',
    span: 2
  },
  { // 企业名称
    label: t('enterprise.name'),
    prop: 'enterprise_name'
  },
  { // 姓名
    label: t('user.sub_user_name'),
    prop: 'sub_user_name'
  },
  { // 联系电话
    key: 'phone',
    label: t('user.sub_user_phone')
  },
  { // 项目
    label: t('dashboard.project'),
    prop: 'project_name'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name'
  },
  { // 是否首次申请
    label: t('user.is_first'),
    prop: 'is_first'
  },
  { // 申请数量
    label: t('assign.application_count'),
    prop: 'content_value'
  },
  { // 使用周期
    label: t('order.order_cycle'),
    prop: 'use_period'
  },
  { // 所属子用户
    label: t('project.sub_user'),
    prop: 'create_user'
  },
  { // 审核状态
    label: t('enterprise.review_status'),
    prop: 'status',
    span: 2
  },
  { // 注册码
    label: t('project.license'),
    prop: 'lic_key',
    span: 2
  },
  { // 激活码
    label: t('assign.license_secret'),
    prop: 'lic_secret',
    span: 2
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment',
    span: 2
  }
]

// 分配历史订单表格
export const assignTableTemplate = (proxy) => {
  return {
    header: [
      { // 订单编号
        key: 'order_id',
        label: t('order.order_code'),
        fixed: 'left',
        width: '90px',
        custem: (data) => {
          return data.assignment ? data.assignment.order.id : '-'
        }
      },
      { // 授权数量
        key: 'content_hosts',
        label: t('assign.assign_count'),
        width: '160px',
        custem: (data) => {
          return data.assignment.content ? proxy.$numberToCurrencyNo(data.assignment.content.hosts) : '-'
        }
      },
      { // 分配日期
        key: 'created_at',
        label: t('order.allocation_time'),
        custem: (data) => {
          return data.assignment ? dayjs(data.assignment.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'
        }
      },
      { // 使用到期时间
        key: 'expired_at',
        label: t('order.expiration_time'),
        custem: (data) => {
          return data.assignment ? dayjs(data.assignment.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-'
        }
      },
      { // 激活码
        key: 'hosts',
        label: t('assign.license_secret'),
        custem: (data) => {
          return data.assignment ? data.assignment.lic_secret : '-'
        }
      }
    ],
    data: []
  }
}

// 申请历史表格
export const applyTableTemplate = (proxy, getApplicationDetail, getAssignmentList, type = null) => {
  if (type) {
    return {
      header: [
        { // 企业名称
          key: 'enterprise_name',
          label: t('enterprise.name'),
          custem: (data) => {
            return data.params ? data.params.enterprise_name : '-'
          }
        },
        { // 姓名
          key: 'user_name',
          label: t('user.sub_user_name'),
          custem: (data) => {
            return data.params ? data.params.user_name : '-'
          }
        },
        { // 联系电话
          key: 'phone',
          label: t('user.sub_user_phone'),
          custem: (data) => {
            return data.params ? data.params.phone : '-'
          }
        },
        { // 申请日期
          key: 'created_at',
          label: t('table.apply_time'),
          width: '180px'
        },
        { // 申请状态
          key: 'hosts',
          label: t('assign.apply_status'),
          custem: (data) => {
            return data.status ? proxy.$formatApplicatState(data.status, 'html') : '-'
          }
        },
        {
          label: t('table.action'),
          width: '80px',
          fixed: 'right',
          handleFun: [
            {
              name: t('table.detail'),
              fn: (data) => {
                getApplicationDetail(data.id)
                getAssignmentList(data.project.id, data.id)
              }
            }
          ]
        }
      ],
      data: []
    }
  }
  return {
    header: [
      { // 项目名称
        key: 'project_name',
        label: t('project.project_name'),
        custem: (data) => {
          return data.project ? data.project.name : '-'
        }
      },
      { // 所属子用户
        key: 'project_name',
        label: t('project.sub_user'),
        custem: (data) => {
          return data.project ? data.project.name : '-'
        }
      },
      { // 申请日期
        key: 'created_at',
        label: t('table.apply_time')
      },
      { // 申请状态
        key: 'hosts',
        label: t('assign.apply_status'),
        custem: (data) => {
          return data.status ? proxy.$formatApplicatState(data.status, 'html') : '-'
        }
      },
      {
        label: t('table.action'),
        width: '80px',
        fixed: 'right',
        handleFun: [
          {
            name: t('table.detail'),
            fn: (data) => {
              getApplicationDetail(data.id)
              getAssignmentList(data.project.id, data.id)
            }
          }
        ]
      }
    ],
    data: []
  }
}

// 子用户申请表格
export const subApplicaitonTable = (proxy, confirmAss, closeApplication, openDetail) => {
  return {
    header: [
      { // 申请创建时间
        key: 'created_at',
        label: t('assign.application_creation_time'),
        width: '300px',
        sortable: true
      },
      { // 申请编号
        key: 'id',
        label: t('assign.application_id'),
        width: '160px',
        sortable: true
      },
      { // 申请来源
        key: 'source',
        width: '300px',
        label: t('assign.application_source'),
        custem: (data) => {
          return data.source === 1 ? t('system.name') : t('assign.scan_code')
        }
      },
      { // 项目
        key: 'project_name',
        label: t('dashboard.project'),
        minWidth: '300px',
        custem: (data) => {
          return data.project ? data.project.name : '-'
        }
      },
      { // 产品名称
        key: 'product_name',
        minWidth: '268px',
        label: t('product.product_name'),
        sortable: true,
        custem: (data) => {
          return data.project ? (data.project.product ? data.project.product.name : '-') : '-'
        }
      },
      { // 申请数量
        key: 'content.hosts',
        label: t('assign.application_count'),
        sortable: true,
        width: '120px',
        custem: (data) => {
          return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '-'
        }
      },
      { // 审核状态
        key: 'status',
        sortable: true,
        label: t('enterprise.review_status'),
        width: '120px',
        custem: (data) => {
          return proxy.$formatApplicatState(data.status, 'html')
        }
      },
      {
        label: t('table.action'),
        width: '138px',
        fixed: 'right',
        handleFun: [
          {
            name: t('assign.assign'),
            hidden: () => {
              return !!!proxy.$userRole(['Enterprise'])
            },
            fn: confirmAss,
            disabled: (data) => {
              return data.status !== 0
            }
          },
          {
            name: t('form.revoke'),
            fn: closeApplication,
            hidden: () => {
              return !!!proxy.$userRole(['Subordinate'])
            },
            disabled: (data) => {
              return data.status !== 0
            }
          },
          {
            name: t('table.detail'),
            fn: openDetail
          }
        ]
      }
    ]
  }
}

// 扫码用户申请表格
export const scanApplicaitonTable = (proxy, confirmAss, openDetail) => {
  return {
    header: [
      { // 申请创建时间
        key: 'created_at',
        label: t('assign.application_creation_time'),
        width: '220px'
      },
      { // 申请编号
        key: 'id',
        label: t('assign.application_id'),
        width: '180px'
      },
      { // 申请来源
        key: 'source',
        label: t('assign.application_source'),
        width: '180px',
        custem: () => {
          return t('assign.scan_code')
        }
      },
      { // 产品名称
        key: 'product_name',
        minWidth: '268px',
        label: t('product.product_name'),
        sortable: true,
        custem: (data) => {
          return data.project ? (data.project.product ? data.project.product.name : '-') : '-'
        }
      },
      { // 产品用户名
        key: 'saas_name',
        label: t('product.user_product'),
        width: '180px',
        custem: (data) => {
          return data.params ? data.params.saas_name : '-'
        }
      },
      { // 企业名称
        key: 'enterprise_name',
        label: t('enterprise.name'),
        width: '180px',
        custem: (data) => {
          return data.params ? data.params.enterprise_name : '-'
        }
      },
      { // 姓名
        key: 'username',
        label: t('user.sub_user_name'),
        custem: (data) => {
          return data.params ? data.params.user_name : '-'
        }
      },
      { // 联系电话
        key: 'phone',
        label: t('user.sub_user_phone'),
        width: '180px',
        custem: (data) => {
          return data.params ? data.params.phone : '-'
        }
      },
      { // 是否首次申请
        key: 'is_first',
        label: t('user.is_first'),
        width: '180px',
        custem: (data) => {
          return data.is_first ? `<span class="default">${t('table.yes')}</span>` : `<span class="faild">${t('table.no')}</span>`
        }
      },
      { // 审核状态
        key: 'status',
        label: t('enterprise.review_status'),
        width: '120px',
        custem: (data) => {
          return proxy.$formatApplicatState(data.status, 'html')
        }
      },
      {
        label: t('table.action'),
        width: '110px',
        fixed: 'right',
        handleFun: [
          {
            name: t('assign.assign'),
            hidden: () => {
              return !!!proxy.$userRole(['Enterprise'])
            },
            fn: confirmAss,
            disabled: (data) => {
              return data.status !== 0
            }
          },
          {
            name: t('table.detail'),
            fn: openDetail
          }
        ]
      }
    ]
  }
}